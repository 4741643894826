import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'

import Footer from '../components/Footer'
import Header from '../components/Header'
import Logo from '../images/top_mainimage.png'

class MoviePage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isArticleVisible: false,
            timeout: false,
            articleTimeout: false,
            article: '',
            loading: 'is-loading'
        }
        this.handleOpenArticle = this.handleOpenArticle.bind(this)
        this.handleCloseArticle = this.handleCloseArticle.bind(this)
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    componentDidMount() {
        this.timeoutId = setTimeout(() => {
            this.setState({ loading: '' });
        }, 100);
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleOpenArticle(article) {

        this.setState({
            isArticleVisible: !this.state.isArticleVisible,
            article
        })

        setTimeout(() => {
            this.setState({
                timeout: !this.state.timeout
            })
        }, 325)

        setTimeout(() => {
            this.setState({
                articleTimeout: !this.state.articleTimeout
            })
        }, 350)

    }

    handleCloseArticle() {

        this.setState({
            articleTimeout: !this.state.articleTimeout
        })

        setTimeout(() => {
            this.setState({
                timeout: !this.state.timeout
            })
        }, 325)

        setTimeout(() => {
            this.setState({
                isArticleVisible: !this.state.isArticleVisible,
                article: ''
            })
        }, 350)

    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.isArticleVisible) {
                this.handleCloseArticle();
            }
        }
    }




    render() {
        return (
            <Layout location={this.props.location} >
                <div>
                    <Header />
                    <div id="movie">
                        <div className="movietitle">XRクリエイティブアワード×VRAA02合同授賞式</div>
                        <div className="youtube">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/kuVvM91mdNI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="movietitle">VRAA02 公開審査配信 届木ウカ視点</div>
                        <div className="youtube">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/dU0SO7KU3SQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="movietitle">VRAA02 公開審査配信</div>
                        <div className="youtube">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/A0IZxID3p4w" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="movietitle">VRAA02 バーチャルに生きるフロンティア！ / 中間配信</div>
                        <div className="youtube">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/yeKAA4N8Hlc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="movietitle">VRAA02 キックオフ配信</div>
                        <div className="youtube">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/u05aXV6nSfE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>

                    </div>
                    <div id="wrapper-footer">
                        <Footer timeout={this.state.timeout} />
                    </div>
                    <div id="bg"></div>
                </div>
                <Helmet>
                    <html lang="jp" />
                    <title>VRAA02配信動画</title>
                    <meta name="description" content="Virtual Reality Architecture Award(VRAA)は、人類の生き方や楽しみ方をアップデートするVR空間＝ワールドを幅広く募集するデザインアワードです。今年のテーマは「Live/Frontier」新作・過去作問わず、人類の生き方や楽しみ方をアップデートするVRワールドを幅広く募集します！" />
                    <meta property="og:site_name" content="VRAA02" />
                    <meta property="og:image" content='https://vraa.jp/static/top_mainimage-fbe5e76929f9ae30bdaac989a322d96b.png' />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="VRAA02" />
                    <meta name="twitter:description" content="Virtual Reality Architecture Award(VRAA)は、人類の生き方や楽しみ方をアップデートするVR空間＝ワールドを幅広く募集するデザインアワードです。今年のテーマは「Live/Frontier」新作・過去作問わず、人類の生き方や楽しみ方をアップデートするVRワールドを幅広く募集します！" />
                    <meta name="twitter:image" content='https://vraa.jp/static/top_mainimage-fbe5e76929f9ae30bdaac989a322d96b.png' />
                </Helmet>
            </Layout>
        )
    }
}

export default MoviePage